<template>
  <v-toolbar color="background" absolute class="jw-app-header jw-app-header--room">
    <v-toolbar-title data-ko="나눔방"> {{ $t("room.list.title") }} </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn size="24" @click="$router.push('/room/find')">
      <v-icon size="20">jwIcons:svg/search-20</v-icon>
    </v-btn>
    <v-btn size="24" class="ml-4" @click="$router.push('/room/make')">
      <v-icon size="24">jwIcons:svg/comment-plus</v-icon>
    </v-btn>
  </v-toolbar>
</template>
