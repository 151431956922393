<template>
  <ul class="jw-room jw-room--list mt-3">
    <li
      v-for="(room, index) in props.myRoomList"
      :ref="(el) => (roomElRefs[room.id] = el)"
      :key="`${room.id}-${index}`"
      class="jw-room--item"
      :class="{
        'jw-room__disabled': ['waiting_invited', 'waiting_asked'].includes(room.gubun),
        'is-pc': isPc,
        selected: room.selected && selectedBackground,
      }"
    >
      <div class="left-action">
        <div class="pin">
          <button type="button" @click.stop="setFixRoom(room)">
            <VIcon size="18" :class="{ pinup: room.pin }">jwIcons:svg/pin</VIcon>
          </button>
          <button type="button" @click.prevent="delUnread(room)" class="read" :class="{ unread: room.notReadCount }">
            <VIcon size="18">jwIcons:svg/check-circle</VIcon>
          </button>
        </div>
      </div>
      <div
        class="jw-room--contents mx-5"
        :title="room.roomName"
        @click.prevent="openDiaryList(room)"
        v-touch="{
          start: (event) => swipeStart(event, room.id, room.status),
          move: (event) => swipeMove(event, room.id, room.status),
          end: (event) => swipeEnd(event, room.id, room.status),
        }"
      >
        <MemberThumbs class="avatar" :room-members="room" />
        <div class="header">
          <div class="title">
            <h3>{{ room.roomName }}</h3>
            <small>{{ room.memCnt }}{{ room.memCnt == 1 ? $t("room.list.person") : $t("room.list.people") }}</small>
            <VIcon
              v-if="!['waiting_invited', 'waiting_asked'].includes(room.gubun) && (room.pin || isPc)"
              size="18"
              class="pin"
              :class="{ pinned: isPc && room.pin }"
              @click.stop="setFixRoom(room)"
              >jwIcons:svg/pin</VIcon
            >
          </div>
          <div class="desc" v-html="room.roomDesc.replace(/\&nbsp;/g, '&')" />
        </div>
        <div class="action">
          <div v-if="room.status === 'LIVE'" class="info align-self-start">
            <p class="time">{{ formatDate(room.writeLastDate) }}</p>
            <v-badge v-if="room.notReadCount" :content="room.notReadCount" inline color="#FF3D00"> </v-badge>
          </div>
          <span v-if="room.status === 'WAITING'">{{ $t("room.list.waiting") }}...</span>
          <VBtn
            v-if="room.status === 'READY' || (room.status === 'INVITED' && room.jsonData.fdbckNotiCd)"
            height="25"
            variant="flat"
            rounded
            color="primary"
            class="px-2"
            @click.stop="acceptJoinRoom(room)"
            data-ko="시작"
            >{{ $t("room.list.btn.start") }}</VBtn
          >
          <VBtn
            v-if="'waiting_invited' == room.gubun && room.jsonData.fdbckNotiCd"
            height="25"
            variant="flat"
            rounded
            color="white"
            class="px-2 ml-2"
            @click.stop="rejectJoinRoom(room)"
            data-ko="거절"
            >{{ $t("room.list.btn.reject") }}</VBtn
          >
          <VBtn v-if="room.status === 'JOIN'" height="25" variant="flat" rounded color="secondary" class="px-2" data-ko="신청"
            >{{ $t("room.list.openlist.btn.apply") }}
          </VBtn>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { useAuthStore } from "~/store/auth";
import { useSharingStore } from "~/store/sharing";
import dayjs from "dayjs";
import MemberThumbs from "@/components/common/MemberThumbs.vue";
import { throttle } from "lodash";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const roomStore = useSharingStore();
const props = defineProps({
  isPc: Boolean,
  myRoomList: Array,
  roomListName: String,
  roomListLength: [Number, String],
});

let startX = null;
let startY = null;
let maxDist = 116;
let minDist = maxDist / 2;

function swipeStart(e, roomId, status) {
  try {
    if (status != "LIVE") return false;

    startX = e.touchstartX;
    startY = e.touchstartY;

    for (let room in roomElRefs.value) {
      if (room !== roomId) {
        const roomEl = roomElRefs.value[room];
        if (roomEl) {
          //console.log("roomEl :: ", roomEl);
          const leftAction = roomEl.querySelectorAll(".left-action .pin")[0];

          const roomContent = roomEl.querySelectorAll(".jw-room--contents")[0];

          if (leftAction) {
            leftAction.style.transform = `translateX(0)`;
          }
          if (roomContent) {
            roomContent.style.transform = `translateX(0)`;
          }

          /* leftAction.style.transform = `translateX(0)`;
          roomContent.style.transform = `translateX(0)`; */
        }
      }
    }
  } catch (err) {
    console.error(`RoomList swipeStart err : `, err);
  }
}

function swipeMove(e, roomId, status) {
  if (status != "LIVE") return false;
  let endX = e.touchmoveX;
  let endY = e.touchmoveY;
  let diffX = endX - startX;
  let diffY = endY - startY;

  if (Math.abs(diffY) > Math.abs(diffX)) {
    return false; // diff = 0;
  } else if (diffX > maxDist) {
    diffX = maxDist;
  } else if (diffX < 0) {
    diffX = 0;
  }

  const roomEl = roomElRefs.value[roomId];
  const leftAction = roomEl.querySelectorAll(".left-action .pin")[0];
  const roomContent = roomEl.querySelectorAll(".jw-room--contents")[0];
  leftAction.style.transform = `translateX(${diffX}px)`;
  roomContent.style.transform = `translateX(${diffX}px)`;
}

function swipeEnd(e, roomId, status) {
  if (status != "LIVE") return false;
  let endX = e.touchendX;
  let endY = e.touchmoveY;
  let diffX = endX - startX;
  let diffY = endY - startY;

  let direction = Math.abs(diffX) === 0 ? 0 : endX > startX ? "RIGHT" : "LEFT";
  if (Math.abs(diffY) > Math.abs(diffX)) {
    diffX = 0;
  } else if (direction === "LEFT") {
    diffX = Math.abs(diffX) <= minDist ? maxDist : 0;
  } else if (direction === "RIGHT") {
    diffX = Math.abs(diffX) <= minDist ? 0 : maxDist;
  }

  const roomEl = roomElRefs.value[roomId];
  const leftAction = roomEl.querySelectorAll(".left-action .pin")[0];
  const roomContent = roomEl.querySelectorAll(".jw-room--contents")[0];
  leftAction.style.transform = `translateX(${diffX}px)`;
  roomContent.style.transform = `translateX(${diffX}px)`;
  startX = null;
  startY = null;
}

const roomElRefs = ref([]);

function formatDate(dateString) {
  if (isEmpty(dateString)) return "";
  // 현재 날짜와 비교할 날짜 객체를 생성합니다.

  const inputDate = dayjs(dateString, "YYYYMMDDHHmmss");
  const now = dayjs();

  // 오늘 날짜와 비교합니다.
  if (inputDate.isSame(now, "day")) {
    // 오늘 날짜와 일치하면 HH:mm 형식으로 반환합니다.
    return inputDate.format("HH:mm");
  } else if (inputDate.isSame(now.subtract(1, "day"), "day")) {
    // 어제 날짜와 일치하면 '어제'를 반환합니다.
    return "어제";
  } else if (inputDate.isBefore(now.subtract(1, "day")) && inputDate.isSame(now, "year")) {
    // 어제보다 이전이고 올해 날짜이면 MM.DD 형식으로 반환합니다.
    return inputDate.format("MM.DD");
  } else {
    // 올해 날짜와 일치하지 않으면 YY.MM.DD 형식으로 반환합니다.
    return inputDate.format("YY.MM.DD");
  }
}

async function setFixRoom(obj) {
  let delYn = obj.fixedYn === "N" ? "N" : "Y";
  const res = await roomStore.setFixActor(obj.roomCd, delYn);
  if (!res) return false;

  // 새로운 상태 업데이트
  let newFixYn = obj.fixedYn === "Y" ? "N" : "Y";
  let updatedObj = { ...obj, fixedYn: newFixYn, pin: newFixYn === "Y" };

  // 현재 배열에서 obj를 제외한 배열 생성
  let otherRooms = roomStore.state.myRoomList.filter((itm) => itm.roomCd !== obj.roomCd);

  if (newFixYn === "Y") {
    // 'Y'인 경우, 맨 앞으로 이동
    roomStore.state.myRoomList = [updatedObj, ...otherRooms];
  } else {
    // 'N'인 경우, fixedYn이 'Y'인 항목들 뒤에 배치
    // fixedYn이 'Y'인 마지막 항목 찾기
    let lastFixedIndex = otherRooms.lastIndexOf(
      otherRooms
        .slice()
        .reverse()
        .find((itm) => itm.fixedYn === "Y")
    );
    // 'Y'인 항목들이 없을 경우 0, 있을 경우 해당 인덱스 + 1
    let insertIndex = lastFixedIndex === -1 ? 0 : lastFixedIndex + 1;
    otherRooms.splice(insertIndex, 0, updatedObj); // 적절한 위치에 삽입
    roomStore.state.myRoomList = [...otherRooms];
  }
}

async function delUnread(obj) {
  //console.log("delUnread :: ", obj);
  //if(obj.notReadCount == 0) return false;

  await roomStore.changeRoomAlarmActor({
    roomCd: obj.roomCd,
    joinStDate: obj.JoinStDate,
    memCd: authStore.userInfo.memCd,
    readMarkDate: dayjs().format("YYYYMMDDHHmmss"),
  });
  //roomStore.getMyRoomActor();
  roomStore.state.myRoomList.forEach((itm) => {
    if (itm.roomCd == obj.roomCd) {
      itm.notReadCount = 0;
    }
  });
  const roomEl = roomElRefs.value[obj.roomCd];
  const leftAction = roomEl.querySelectorAll(".left-action .pin")[0];
  const roomContent = roomEl.querySelectorAll(".jw-room--contents")[0];
  leftAction.style.transform = `translateX(0)`;
  roomContent.style.transform = `translateX(0)`;
}

async function openDiaryList(obj) {
  try {
    /* roomStore.state.myRoomList.forEach((itm) => {
      if (itm.roomCd == obj.roomCd) itm.selected = true;
      else itm.selected = false;
    }); */
    //roomStore.state.myRoomList = roomStore.state.myRoomList.map((itm) => ({ ...itm, selected: itm.roomCd == obj.roomCd }));
    /* console.log("openDiaryList obj :: ", obj);
    console.log("roomStore.state.myRoomList :: ", roomStore.state.myRoomList); */
    if (obj.status != "LIVE") return false;

    roomStore.state.roomDiaryTitle = obj.roomName;
    //router.push(`/room/list?roomCd=${obj.roomCd}&startNum=0`)
    //router.push(`/room/${obj.roomCd}?startNum=0`);
    roomStore.state.roomDiaryList = [];
    await nextTick();
    roomStore.state.toCallRoomCd = obj.roomCd;
    roomStore.state.startNum = "0";

    await roomStore.getRoomDiaryListActor({ roomCd: obj.roomCd, startNum: "0" });

    router.push({ name: "room-roomCd", params: { roomCd: obj.roomCd }, query: { startNum: 0 } });
  } catch (err) {
    console.error(`openDiaryList err : `, err);
  }
}

function extractAlarmCode(data) {
  try {
    // 데이터 문자열을 ', '로 분리하여 배열에 저장
    const parts = data.split(", ");
    const result = {};

    // 각 부분을 순회하며 'Y:' 또는 'N:'로 시작하는 부분을 찾아서 객체에 저장
    parts.forEach((part) => {
      if (part.startsWith("Y:")) {
        result.Y = part.split(":")[1];
      } else if (part.startsWith("N:")) {
        result.N = part.split(":")[1];
      }
    });

    return result; // 결과 객체 반환
  } catch (error) {
    console.error(`extractAlarmCode error : ${error}`);
  }
}

let acceptJoinRoomAble = ref(true);
const acceptJoinRoom = throttle(async (obj) => {
  try {
    if (!acceptJoinRoomAble.value) return;
    acceptJoinRoomAble.value = false;

    console.log("acceptJoinRoom obj :: ", obj);
    console.log("state.myRoomList :: ", roomStore.state.myRoomList);
    //const { roomCd, jsonData, sendMemCd, rcvMemCd, siteLangCd } = obj;
    const { roomCd, sendMemCd, rcvMemCd, siteLangCd, fdbckNotiCd } = obj.jsonData;
    if (!fdbckNotiCd) return alert("오류가 발생했습니다.");
    const ext = extractAlarmCode(fdbckNotiCd);
    console.log("fdbckNotiCd :: ", fdbckNotiCd);
    console.log("ext :: ", ext);

    const res = await roomStore.respondInvtActor({
      alarmGubun: ext.Y,
      sendMemCd,
      rcvMemCd,
      siteLangCd,
      roomCd,
      mssgWriteDate: dayjs().format("YYYYMMDDHHmmss"),
    });
    if (res) {
      alert("나눔방에 가입되었습니다.");
      roomStore.getMyRoomActor();
    }
  } catch (err) {
    console.error(`acceptJoinRoom err : `, err);
  } finally {
    acceptJoinRoomAble.value = true;
  }
}, 3000);

let rejectJoinRoomAble = ref(true);
const rejectJoinRoom = throttle(async (obj) => {
  try {
    if (!rejectJoinRoomAble.value) return;
    rejectJoinRoomAble.value = false;
    //const { roomCd, jsonData, sendMemCd, rcvMemCd, siteLangCd } = obj.jsonData;
    const { roomCd, sendMemCd, rcvMemCd, siteLangCd, fdbckNotiCd } = obj.jsonData;
    //console.log("obj :: ", obj.jsonData);
    const ext = extractAlarmCode(fdbckNotiCd);
    const res = await roomStore.respondInvtActor({
      alarmGubun: ext.N,
      sendMemCd,
      rcvMemCd,
      siteLangCd,
      roomCd,
      mssgWriteDate: dayjs().format("YYYYMMDDHHmmss"),
      mssgReplyCnts: "나눔방 초대 거절",
    });
    if (res) {
      alert("나눔방 초대를 거절하였습니다.");
      roomStore.getMyRoomActor();
    }
  } catch (err) {
    console.error(`rejectJoinRoom err : `, err);
  } finally {
    rejectJoinRoomAble.value = true;
  }
}, 3000);

const selectedBackground = computed(() => {
  if (roomStore.state?.myRoomList?.length == 0) return false;
  if (route.path == "/room") return false;
  return true;
});

/* watch(
  () => props.myRoomList,
  () => {
    console.log("props.myRoomList :: ", props.myRoomList);
  },
  { deep: true, immediate: true }
); */

watch(
  () => route.name,
  async (v) => {
    await nextTick();
    setTimeout(() => {
      if (route.name == "room-roomCd" && route.params.roomCd) {
        roomStore.state.myRoomList.forEach((itm) => {
          if (itm.roomCd == route.params.roomCd) itm.selected = true;
          else itm.selected = false;
        });
      } else {
        if (roomStore.state.myRoomList?.length > 0) {
          roomStore.state.myRoomList.forEach((itm) => {
            itm.selected = false;
          });
        }
      }
      //console.log("roomStore.state.myRoomList ==> ", roomStore.state.myRoomList);
    }, 500);
  },
  { immediate: true, deep: true }
);
</script>

<style scoped lang="scss">
.selected {
  background-color: #faf3eb !important;
  //background-color: black;
}
</style>
