<template>
  <RoomListHeader />
  <v-container :fluid="!isPc" class="jw-room--wrap px-0 pt-4" style="margin-top: 55px">
    <h3 class="jw-room--header px-5" data-ko="나의 나눔방">
      {{ $t("room.list.mylist.title", { count: roomStore.state.myRoomList.length || "" }) }}
    </h3>
    <RoomListMyRoomSection v-if="roomStore.state?.myRoomList?.length > 0" :myRoomList="roomStore.state.myRoomList" :isPc />
    <p v-else class="jw-room--no-data mx-5">가입한 나눔방이 없습니다.</p>
    <h3 class="jw-room--header mt-12 px-5" data-ko="오픈 나눔방">
      {{ $t("room.list.openlist.title", { count: roomStore.state.openRoomList.length || "" }) }}
    </h3>
    <!-- <RoomListOpenTitle /> -->
    <RoomListOpenRoomSection
      v-if="roomStore.state?.openRoomList?.length > 0"
      :openRoomList="roomStore.state.openRoomList"
      :changeRoomStatus="changeRoomStatus"
    />
    <p v-else class="jw-room--no-data mx-5" data-ko="오픈된 나눔방이 없습니다.">{{ $t("room.list.openlist.empty") }}</p>
  </v-container>
  <!-- <JwBottomNav v-if="!!isPc && !!isMobile" /> -->
</template>

<script setup>
import RoomListHeader from "./roomList/RoomListHeader.vue";
import RoomListMyRoomSection from "./roomList/RoomListMyRoomSection.vue";
import RoomListOpenTitle from "./roomList/RoomListOpenTitle.vue";
import RoomListOpenRoomSection from "./roomList/RoomListOpenRoomSection.vue";
//import MyRoomItem from './MyRoomItem.vue';
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import { useDisplay } from "vuetify";
import { useSharingStore } from "~/store/sharing";

const router = useRouter();
const { xlAndUp, smAndDown } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const isMobile = computed(() => smAndDown.value);
const roomStore = useSharingStore();
const route = useRoute();

defineComponent({
  name: "RoomList",
  layout: "room",
});

/* async function setFixRoom(obj) {
  let delYn;
  if (obj.fixedYn == 'N') delYn = 'N'
  else delYn = 'Y'

  const res = await roomStore.setFixActor(obj.roomCd, delYn)
  if (res != true) return false;

  let newFixYn = {};
  if (obj.fixedYn == 'Y') newFixYn = { Yn: 'N', boolean: false };
  else newFixYn = { Yn: 'Y', boolean: true }

  
  roomStore.state.myRoomList = roomStore.state.myRoomList.map(itm => ({
    ...itm, fixedYn: itm.roomCd == obj.roomCd ? newFixYn.Yn : itm.fixedYn,
    pin: itm.roomCd == obj.roomCd ? newFixYn.boolean : itm.pin
  }))
} */

// 나눔방 요소들
const roomElRefs = ref([]);

watch(
  () => roomElRefs.value,
  () => {
    //console.log("roomElRefs :: ", roomElRefs.value)
  },
  { deep: true, immediate: true }
);

const myRooms = reactive({
  id: "myRooms",
  name: "나의 나눔방",
  list: [
    {
      id: "id1",
      name: "김영민속(남)",
      desc: "순종하는 남자들의 나눔방",
      pin: true,
      status: "LIVE", // LIVE, WAITING, READY, JOIN, INVATED
      unReadChat: 4,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "1",
          name: "홍길동1",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "2",
          name: "홍길동3",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
    {
      id: "id2",
      name: "김영민속(남)",
      desc: "순종하는 남자들의 나눔방",
      pin: true,
      status: "LIVE", // LIVE, WAITING, READY
      unReadChat: 2,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "3",
          name: "홍길동3",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "4",
          name: "홍길동4",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "5",
          name: "홍길동5",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: true,
        },
      ],
    },
    {
      id: "id3",
      name: "아가페 성가대",
      desc: "순종하는 남자들의 나눔방",
      pin: false,
      status: "LIVE", // LIVE, WAITING, READY
      unReadChat: 1,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "6",
          name: "홍길동6",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "7",
          name: "홍길동7",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "8",
          name: "홍길동8",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "9",
          name: "홍길동9",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
    {
      id: "id4",
      name: "아가페 성가대2",
      desc: "순종하는 남자들의 나눔방",
      pin: false,
      status: "LIVE", // LIVE, 상태들
      unReadChat: 0,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "10",
          name: "홍길동10",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
    {
      id: "id5",
      name: "아가페 성가대3",
      desc: "순종하는 남자들의 나눔방",
      pin: false,
      status: "WAITING", // LIVE, 상태들
      unReadChat: 0,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "10",
          name: "홍길동10",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
  ],
});
const openRooms = reactive({
  id: "openRooms",
  name: "오픈 나눔방",
  list: [
    {
      id: "idBnb1",
      name: "그럼에도...",
      desc: "순종하는 남자들의 나눔방",
      pin: true,
      status: "READY", // LIVE, WAITING, READY
      unReadChat: null,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "1",
          name: "홍길동1",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "2",
          name: "홍길동3",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
    {
      id: "idBnb2",
      name: "오직 예수 (남)",
      desc: "순종하는 남자들의 나눔방",
      pin: true,
      status: "READY", // LIVE, WAITING, READY
      unReadChat: null,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "3",
          name: "홍길동3",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "4",
          name: "홍길동4",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "5",
          name: "홍길동5",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: true,
        },
      ],
    },
    {
      id: "idBnb3",
      name: "항상 그렇게 우리는",
      desc: "순종하는 남자들의 나눔방",
      pin: false,
      status: "READY", // LIVE, WAITING, READY
      unReadChat: null,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "6",
          name: "홍길동6",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "7",
          name: "홍길동7",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "8",
          name: "홍길동8",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
        {
          id: "9",
          name: "홍길동9",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
    {
      id: "idBnb4",
      name: "전문직 종사자(여)",
      desc: "순종하는 남자들의 나눔방",
      pin: false,
      status: "READY", // LIVE, WAITING, READY
      unReadChat: null,
      recentChat: {
        author: "홍길동1",
        time: "오전 1:17",
        message: "안녕하세요",
      },
      members: [
        {
          id: "10",
          name: "홍길동10",
          imgSrc: "https://i.pravatar.cc/300",
          isBlock: false,
        },
      ],
    },
  ],
});

function changeRoomStatus(roomCd) {
  roomStore.state.openRoomList.forEach((room) => {
    if (room.roomCd == roomCd) {
      room.status = "WAITING";
    }
  });
}

onMounted(() => {
  //console.log("roomList route :: ", route);
  roomStore.getMyRoomActor().then((list) => {});
  roomStore.getOpenRoomActor().then((list) => {});
});
</script>
