<template>
  <ul class="jw-room jw-room--list mt-3">
    <li
      v-for="(room, index) in props.openRoomList"
      :ref="(el) => (roomElRefs[room.id] = el)"
      :key="`${room.id}-${index}`"
      class="jw-room--item"
      @click.stop="onClickOpenRoom(room)"
    >
      <div class="jw-room--contents mx-5" :title="room.roomName">
        <MemberThumbs class="avatar" :room-members="room" />
        <div class="header">
          <div class="title">
            <h3>{{ room.roomName }}</h3>
            <small>{{ room.memCnt }}{{ room.memCnt == 1 ? $t("room.list.person") : $t("room.list.people") }}</small>
          </div>
          <!-- <div class="desc">{{ room.desc }}</div> -->
          <div class="desc" v-html="room.roomDesc.replace(/\&nbsp;/g, '&')" />
        </div>
        <div class="action">
          <span v-if="room.status == 'WAITING'" data-ko="대기중">{{ $t("room.list.waiting") }}...</span>
          <VBtn v-else height="25" variant="flat" rounded color="secondary" class="px-2" @click.stop="joinOpenRoom(room)" data-ko="신청">{{
            $t("room.list.openlist.btn.apply")
          }}</VBtn>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup>
import MemberThumbs from "@/components/common/MemberThumbs.vue";
import { useAuthStore } from "~/store/auth";
import { useSharingStore } from "~/store/sharing";
import { useI18n } from "vue-i18n";
import { throttle } from "lodash";
import dayjs from "dayjs";
const authStore = useAuthStore();
const roomStore = useSharingStore();
const router = useRouter();

const props = defineProps({
  openRoomList: Array,
  changeRoomStatus: Function,
});

const { t } = useI18n();

const roomElRefs = ref([]);

let joinOpenRoomAble = ref(true);
const joinOpenRoom = throttle(async (obj) => {
  try {
    if (!joinOpenRoomAble.value) return;
    joinOpenRoomAble.value = false;

    if (roomStore.state.myOpenRoomCnt > 1) {
      //return alert("오픈 나눔방은 2개까지 가입 가능합니다.");
      return alert(t("room.list.openlist.limit"));
    }

    if (
      !confirm(
        `나눔방은 신뢰할 만한 사람들과 함께 나누는 작은 공동체입니다.\n나눔방장님이 수락하신 후 함께 나눔방에서 나눌 수 있습니다.\n나눔방에 가입 신청을 하시겠습니까?`
      )
    )
      return false;

    const res = await roomStore.joinOpenRoomAskActor({
      grpCd: "129",
      roomCd: obj.roomCd,
      sendMemCd: authStore.userInfo.memCd,
      memCd: authStore.userInfo.memCd,
      selfAgreeYn: "Y",
      cnfmFnshDate: dayjs().format("YYYYMMDDHHmmss"),
      cnfmYn: "Y",
      invtYn: "N",
      siteLangCd: "COMMONCD001000000001",
    });
    alert("오픈 나눔방에 가입신청 되었습니다.");
    //해당 방의 버튼을 ...대기중으로 변경 처리
    props.changeRoomStatus(obj.roomCd);

    return;
  } catch (err) {
    console.error(`joinOpenRoom error ===> ${err}`);
  } finally {
    joinOpenRoomAble.value = true;
  }
}, 3000);

async function onClickOpenRoom(obj) {
  console.log("onClickOpenRoom obj ====> ", obj);
  const res = await roomStore.getRoomInfoActor({ roomCd: obj.roomCd, gubun: "roomMain" });
  console.log("오픈 나눔방 정보 ===> ", res);
  router.push(`/room/info/${obj.roomCd}`);
}
</script>
