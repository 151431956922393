<template>
  <ClientOnly>
    <v-layout
      :style="{
        position: isPc ? 'relative' : undefined,
        flexDirection: isPc ? 'column' : 'row',
      }"
    >
      <JwGnbHeader v-if="isPc" />
      <v-navigation-drawer
        v-model="sideNavWrap.show"
        name="left"
        touchless
        :class="{ 'scroll-bar': isPc }"
        :absolute="isPc"
        :style="{
          width: !isMobile ? '337px' : '100%',
          height: isPc ? 'auto' : 'calc(100vh - 55px)',
          minHeight: isPc ? 'calc(100vh - 55px)' : undefined,
          bottom: isPc ? '155px' : 'var(--v-layout-bottom)',
          left: isPc ? '50%' : undefined,
          marginLeft: isPc ? '-450px' : undefined,
          paddingTop: isPc ? `50px` : undefined,
          transition: 'none !important',
        }"
      >
        <!-- {{ `isPc:${isPc}, isTablet:${isTablet}, isMobile:${isMobile}` }}
      {{ `sideNavWrap.show:${isPc}, sideNavWrap.width:${sideNavWrap.width}` }} -->
        <!-- 나눔방 메인의 좌측에 나오는 나눔방 리스트 -->
        <RoomList />
      </v-navigation-drawer>
      <VMain
        name="main"
        :style="{
          '--v-layout-top': !isPc ? 0 : '50px',
          '--v-layout-left': !isPc && isTablet && !isMobile ? '337px' : 0,
          '--v-layout-bottom': 0,
          backgroundColor: '#FFFCF8',
          overflow: sideNavWrap.show && isMobile ? 'hidden' : undefined,
        }"
      >
        <v-container
          ref="rightMainContainer"
          v-resize="onResize"
          :fluid="!isPc"
          :class="{ 'scroll-bar': isPc, rightMainContainer: true }"
          :style="{
            padding: 0,
            paddingTop: isPc ? '55px' : 0,
            paddingLeft: isPc ? '337px' : 0,
            paddingBottom: isMobile ? '55px' : 0,
            minHeight: isPc ? `calc(100vh - 210px)` : undefined,
            maxHeight: '100vh',
            overflow: 'auto',
            background: isPc ? '#faf3eb' : undefined,
          }"
        >
          <div ref="jwMainContainer">
            <!-- 나눔방 오른쪽에 나오는 페이지 -->
            <!-- <KeepAlive :include="['room-roomCd']">
              <NuxtPage />
            </KeepAlive> -->

            <NuxtPage @get:scrollValue="getScrollValue" />
          </div>
        </v-container>
      </VMain>
      <JwFooter v-if="showMainFooter" />
      <JwBottomNav
        v-if="bottomNavShow"
        :style="{
          width: isMobile ? '100%' : '337px',
          left: isMobile ? 0 : undefined,
          paddingLeft: isMobile ? 0 : undefined,
          zIndex: 1007,
          borderRight: !isMobile ? '1px solid rgba(var(--v-border-color), var(--v-border-opacity))' : undefined,
        }"
      />
      <JwConfirmDialog />
    </v-layout>
  </ClientOnly>
</template>
<script setup>
import JwConfirmDialog from "~/components/common/JwConfirmDialog.vue";
import RoomList from "@/components/room/RoomList.vue";
import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import JwFooter from "@/components/common/JwFooter.vue";
import { useDisplay } from "vuetify";
const route = useRoute();
const { smAndDown, xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const isMobile = computed(() => smAndDown.value);
const isTablet = computed(() => !isPc.value && !isMobile.value);

const bottomNavShow = computed(() => {
  if (isPc.value) return false;
  if (isTablet.value) {
    return ["room", "room-roomCd", "room-roomCd-diaryCd-diaryCd", "room-find", "MakeRoom"].includes(route.name);
  }
  if (isMobile.value) {
    return ["room", "room-roomCd", "room-find", "MakeRoom"].includes(route.name);
  }
});

definePageMeta({
  keepalive: {
    include: ["room-roomCd"],
  },
});

const jwMainContainer = ref(null);
const rightMainContainer = ref(null);
provide("rightMainContainer", rightMainContainer);

const sideNavWrap = reactive({
  width: "337px",
  height: "auto",
  show: true,
});

watch(
  () => sideNavWrap,
  () => {
    //console.log("sideNavWrap ======> ", sideNavWrap);
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  onResize("onMounted");
});
onUpdated(() => {
  onResize("onUpdated");
});
watch(() => route.path, onResize);

function onResize(from) {
  sideNavWrap.width = !isMobile.value ? "337px" : "100%";
  sideNavWrap.height = jwMainContainer.value?.getBoundingClientRect().height;
  //sideNavWrap.show = !isMobile.value || route.path.endsWith("/room")
  /*

  */
  if (!isMobile.value) {
    sideNavWrap.show = true;
  } else {
    if (route.name == "room") {
      sideNavWrap.show = true;
    } else {
      nextTick(() => {
        setTimeout(() => {
          if (["room"].includes(route.name) && !isMobile.value) {
            sideNavWrap.show = false;
          }
          if (["room-roomCd", "room-roomCd-diaryCd-diaryCd", "room-find", "MakeRoom", "room-info-roomCd"].includes(route.name) && isMobile.value) {
            sideNavWrap.show = false;
          }
        }, 500);
      });
    }
  }

  // console.log(
  //   !isMobile.value,
  //   sideNavWrap.show,
  //   jwMainContainer.value.getBoundingClientRect().height,
  //   currentPage,
  //   !['view', 'list'].includes(currentPage[currentPage.length - 1])
  // );
}

const showMainNav = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return !isPc.value;
  }
});

const showMainFooter = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return isPc.value ? true : false;
  }
});

function getScrollValue() {
  const scrollValue = jwMainContainer.value.scrollTop;
  const clientHeight = jwMainContainer.value.clientHeight;
}
</script>
